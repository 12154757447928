
import { useDispatch, useSelector } from 'react-redux';
import { AppActions, Callbacks } from '../app-actions';
import { AppState, RequestTypes } from "../state-type";
import { useCallback } from 'react';

type UsePredictionsActions = {
  getPredictions: (data:RequestTypes['getPredictions'], callbacks?:Callbacks)=>void,
}
type UsePredictions = UsePredictionsActions & {
  predictions: AppState['predictions'],
  predictionsLoading: AppState['predictionsLoading'],
  predictionsError: AppState['predictionsError'],
  getPredictions: (data:RequestTypes['getPredictions'], callbacks?:Callbacks)=>void,
}

export default function usePredictions():UsePredictions {
  const {
    predictions, predictionsLoading, predictionsError } = useSelector((state:AppState)=>({
    predictions: state.predictions,
    predictionsLoading: state.predictionsLoading,
    predictionsError: state.predictionsError,
  }));
  const dispatch = useDispatch();
  const getPredictions = useCallback((data:RequestTypes['getPredictions'], callbacks?:Callbacks)=>{
    return dispatch(AppActions.getPredictions(data, callbacks))
  },[dispatch])
  return {
    predictions,
    predictionsLoading,
    predictionsError,
    getPredictions,
  }
}

export function usePredictionsActions():UsePredictionsActions {
  const dispatch = useDispatch();
  const getPredictions = useCallback((data:RequestTypes['getPredictions'], callbacks?:Callbacks)=>{
    return dispatch(AppActions.getPredictions(data, callbacks))
  },[dispatch])
  return {
    getPredictions,
  }
}
