import BestBetsContainer, {
    bestBetsContainerProps,
} from 'components/BestBetsContainer/BestBetsContainer'
import { InSeasonSportsContainer } from 'components/InSeasonSportsContainer'
import { Featured, Main } from 'components/Layout/Layout'
import {
    NextUpContainer,
    nextUpContainerProps,
    NextUpContainerType,
} from 'components/NextUpContainer'
import {
    EditorialGridContainer,
    editorialGridContainerProps,
} from 'components/EditorialGridContainer/EditorialGridContainer'
import {
    PredictionsContainer,
    predictionsContainerProps,
} from 'components/Predictions/PredictionsContainer'
import { ContentContainer } from 'components/ContentContainer'
import { HeroContainer, heroContainerProps } from 'components/HeroContainer'
import { NextPageWithLayout } from 'types/nextPageWithLayout'
import {
    ParlaysContainer,
    parlaysContainerProps,
} from 'components/ParlaysContainer/ParlaysContainer'
import { getAnalytics } from 'project/analytics'
import { nextReduxWrapper } from 'components/util/nextReduxWrapper'
import Strings from 'project/localization'
import { ServerSidePageProps } from 'types/serverSidePageProps'
import { combineNextPromiseActions } from 'project/nextPromiseAction'

const nextUpProps: NextUpContainerType['nextUpProps'] = {
    filterTitle: Strings.nextUp,
}

// eslint-disable-next-line @typescript-eslint/ban-types
type IndexPageType = {}

const IndexPage: NextPageWithLayout<IndexPageType> = () => {
    return (
        <>
            <Featured>
                <InSeasonSportsContainer />
                <NextUpContainer
                    nextUpProps={nextUpProps}
                    title={Strings.nextUp}
                />
                <HeroContainer />
            </Featured>
            <Main>
                <ContentContainer>
                    <BestBetsContainer title={Strings.bestBets} />
                    <ParlaysContainer
                        title={Strings.parlayPicks}
                        href={'/parlays'}
                    />
                    <EditorialGridContainer />
                    <PredictionsContainer />
                </ContentContainer>
            </Main>
        </>
    )
}

export const getServerSideProps = nextReduxWrapper.getServerSideProps(
    (store) => async (): Promise<ServerSidePageProps<IndexPageType>> => {
        const pageSlug = '/'
        return {
            props: {
                jsonLd: await combineNextPromiseActions([
                    heroContainerProps(store, pageSlug),
                    bestBetsContainerProps(store, pageSlug),
                    nextUpContainerProps(store, pageSlug),
                    parlaysContainerProps(store, pageSlug),
                    predictionsContainerProps(store, pageSlug),
                    editorialGridContainerProps(store, pageSlug),
                ]),
                pageAnalytics: getAnalytics({
                    category: 'Home',
                    page_name: 'Home',
                    site_section: 'Home',
                    site_section_1: 'Home',
                }),
            },
        }
    }
)

export default IndexPage
