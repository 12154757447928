import { FC } from 'react'
import useSports from '@ssgat/common/providers/useSports'
import { InSeasonSports, useIsMobileWidth } from '@ssgat/react-components'
import { Section } from 'components/Layout/Layout'

export const InSeasonSportsContainer: FC = () => {
    const { inSeasonSports } = useSports()
    const isMobileWidth = useIsMobileWidth()
    if (!isMobileWidth || !inSeasonSports?.data?.length) return null
    return (
        <Section toMobileEdge showDivider={false}>
            <InSeasonSports sports={inSeasonSports.data} />
        </Section>
    )
}

export default InSeasonSportsContainer
