import { Section } from 'components/Layout/Layout'
import usePredictions from '@ssgat/common/providers/usePredictions'
import {
    CardGrid,
    EventCard,
    EventShortcutCard,
    useIsMobileWidth,
} from '@ssgat/react-components'
import Strings from 'project/localization'
import {
    combineNextPromiseActions,
    nextPromiseAction,
} from 'project/nextPromiseAction'
import { ContainerPropsType } from 'types/containerPropsType'
import { FC } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-types
export type PredictionsContainerType = {}

export const PredictionsContainer: FC<PredictionsContainerType> = () => {
    const { predictions } = usePredictions()

    const isMobileWidth = useIsMobileWidth()
    if (!predictions) return null
    return (
        <>
            {predictions.map((sportPredictionsChunk) => {
                const {
                    predictions: sportPredictions = [],
                    title,
                    href,
                    predictionCount,
                } = sportPredictionsChunk

                const single = sportPredictions.length === 1
                const [firstPrediction] = sportPredictions
                const isTeamSport = firstPrediction?.teamBased

                return (
                    <Section
                        toMobileEdge
                        key={sportPredictionsChunk.title}
                        showDivider={!isMobileWidth}
                    >
                        <CardGrid
                            title={title}
                            mobileSlider={true}
                            featuredCard={!isTeamSport && single}
                            href={href}
                            seeAllButton={true}
                            seeAllButtonText={`${Strings.formatString(
                                Strings.seeAllWithNumber,
                                `${predictionCount}`
                            )}`}
                        >
                            {sportPredictions?.map((sportPrediction) => {
                                const { id, teamBased } = sportPrediction
                                return teamBased ? (
                                    <EventCard
                                        {...sportPrediction}
                                        homeTeam={sportPrediction.homeTeam!}
                                        awayTeam={sportPrediction.awayTeam!}
                                        key={id}
                                    />
                                ) : (
                                    <EventShortcutCard
                                        {...sportPrediction}
                                        wide={single}
                                        key={id}
                                    />
                                )
                            })}
                        </CardGrid>
                    </Section>
                )
            })}
        </>
    )
}

export const predictionsContainerProps: ContainerPropsType = async (
    store,
    pageSlug
) => {
    return {
        jsonLd: await combineNextPromiseActions([
            nextPromiseAction<'getPredictions'>(store, 'getPredictions', {
                pageSlug,
            }),
        ]),
    }
}

export default PredictionsContainer
